import React from 'react'
import styled, {css} from "styled-components";
import {LightBox} from "../LightBox/LightBox";
import { useDispatch } from "react-redux";
import { SHARED_CLEAR_INSTANCE } from "../../constants/sharedConstants";

export const BillingAddressChangeDisclaimerModal = () => {
    const dispatch = useDispatch()

    const closeHandler = () => {
        document.body.style.overflow = 'auto'
        dispatch({type: SHARED_CLEAR_INSTANCE})
    }

    return (
        <>
            <LightBox
                styles={css`
                    width: 100%;
                    max-width: 29.5rem;
                    padding-left: 3.2rem;
                    padding-right:3.2rem;
                    padding-bottom: 4rem;
                    border-radius: 0.8rem;
                `}
                show
                closeHandler={closeHandler}
            >
                <DisclaimerTextDiv>
                    The billing address you provided doesn’t match with this form of payment. Please use a different address or payment option.
                </DisclaimerTextDiv>
            </LightBox>
        </>
    )
}

const DisclaimerTextDiv = styled.div`
    font-size: 1.8rem;
    line-height: 133.33%;
    color: ${props => props.theme.darkRed};
`
